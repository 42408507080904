.custom-main-nav {
  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #b91c1c !important;
  }
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #4d5c6d !important;
  font-weight: 600;
  border: 0px !important;
  font-size: 15px;
}

p {
  margin-bottom: 0px !important;
}

.ant-table {

  .ant-table-thead th,
  .ant-table-column-title {
    color: #cccccc;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 2;
    text-transform: uppercase;
  }
}

.ant-carousel .slick-arrow.slick-disabled {
  display: none;
}

.custom-main-nav {
  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #b91c1c !important;
  }
}

.ant-input,
.ant-input:hover,
.ant-input:focus,
.ant-input-focused,
.ant-input-disabled,
.ant-input[disabled] {
  text-transform: none;
}

.ant-table {

  .ant-table-thead th,
  .ant-table-column-title {
    color: #cccccc;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 2;
    text-transform: uppercase;
  }

  .editable-row {
    vertical-align: top;
  }
}

.ant-collapse-borderless>.ant-collapse-item {
  border-bottom: none;
}

.custom-panel .ant-collapse-header {
  font-size: 0.9rem;
  font-weight: 600;
  padding: 0.5rem 1rem !important;
}

.ant-table-summary>tr>th,
.ant-table-summary>tr>td .editable-row {
  vertical-align: top;
}

.ant-collapse-borderless>.ant-collapse-item {
  border-bottom: none;
}

.custom-panel .ant-collapse-header {
  font-size: 0.9rem;
  font-weight: 600;
  padding: 0.5rem 1rem !important;
}

.ant-table-summary>tr>th,
.ant-table-summary>tr>td {
  vertical-align: top;
}

.custom-checkbox.ant-switch {
  height: 42px;
  min-width: 50px;
  background-color: transparent;
  border: 1px solid #b91c1c;

  .ant-switch-inner {
    font-size: 0.9rem;
    font-weight: 500;
    color: #b91c1c;
  }

  .ant-switch-handle {
    &::before {
      background-color: #b91c1c;
    }
  }
}

.custom-phone-input {
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-radius: 0;
  box-shadow: none !important;
  border-bottom-width: 1px;

  &:hover,
  &:focus {
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-radius: 0;
    border-bottom-width: 1px;
  }
}

.ant-select-selector {
  padding-left: 0 10px !important;
  border-radius: 5px !important;
}

.ant-modal-content {
  border-radius: 15px;
}

.ant-modal-header {
  border-radius: 15px 15px 0 0;
}

.ant-select-selection-item {
  text-transform: capitalize;
  font-size: 1rem;
}

.ant-select-item {
  text-transform: capitalize;
  font-size: 1rem;
  color: #000;
}

.custom-role-table {
  .role-row:nth-child(odd) {
    background-color: #f0f0f1;
  }

  .ant-table-thead {
    .ant-table-cell {
      color: #b91c1c;
      font-weight: 700;
    }
  }
}

.ant-collapse-content {
  border: 0px;
}

.main-nav {
  position: relative;

  &::after {
    content: "";
    width: 6px;
    height: 150%;
    right: -32px;
    top: -8px;
    bottom: 0;
    background-color: #b91c1c;
    position: absolute;
    z-index: 2;
    transition: transform 1s ease;
    transform: scaleX(0);
    transform-origin: right;
  }

  &.is-active {
    &::after {
      transform: scaleX(1);
    }
  }
}

.custom-tabset {
  .ant-collapse-header {
    font-weight: 600;
  }
}

.ant-transfer {
  .ant-transfer-list-search {
    padding: 0.5rem;
  }

  .ant-transfer-list-header {
    background-color: #dadada;
  }

  .ant-btn-icon-only.ant-btn-sm {
    width: 2rem;
    height: 2rem;
  }
}

.ant-transfer-operation {
  .ant-btn .anticon {
    font-size: 1rem;
  }

  .ant-btn:first-child {
    margin: 0 0 1rem 0;
  }
}

.values_section p {
  border: 0px;
  background-color: #8a2;
  border-radius: 5px;
  padding: 0px 15px;
  margin-bottom: 5px;
  font-size: 14px;
  color: #fff;
}

.values_section p.green-clr {
  color: #fff;
  background-color: #b71717;
}

.sticky-thc {
  position: sticky;
  top: 0;
}

.Artical_card3 .ant-card-body {
  padding: 0px;
}

.Artical_card3 .ant-collapse-item {
  margin-bottom: 0px;
}

.Artical_card3 .ant-progress-bg {
  background-color: #0b80a4;
}

.sticky-wrapper {
  position: relative;
  height: 3rem;
}

.sticky .sticky-inner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}

.ant-transfer-list-header-title {
  font-size: 1rem;
  color: #ab0011;
  font-weight: 600;
}

.ant-transfer-list {
  background-color: #ededed;
}

.ant-layout-header {
  border: 1px solid #e7e9ec;
  border-top: 0px;
  height: 90px !important;
  padding: 15px 15px !important;
}

.ant-layout-header,
.ant-layout {
  background: #fff !important;
}

.Artical_card1 .ant-card {
  max-width: 100%;
  width: 100%;
  margin-left: auto;
  margin-bottom: 20px;
  border-radius: 6px;
}

.Artical_card2 .momes_border .ant-card.ant-card-bordered {
  border: 0px !important;
}

.Artical_card2 .momes_border .ant-card.ant-card-bordered .ant-card-body {
  padding: 0px !important;
}

.Artical_card2 .ant-card {
  width: 100%;
  border-radius: 6px;
}

.Artical_card2 .ant-tabs-content-holder .ant-card-body {
  padding: 0px;
}

.Artical_card2 .ant-tabs .ant-card {
  border: 0px;
}

.Artical_card2 .ant-tabs .ant-card .ant-card-body {
  padding: 0px;
}

.Artical_card2 .border_align {
  border-bottom: 1px solid #ebebeb;
  padding: 0px 0px 15px 0px;
  text-align: left !important;
}

.Artical_card2 .ant-tabs-content-holder {
  height: 100%;
  max-height: 270px;
  overflow-y: scroll;
}

body {
  overflow-x: hidden;
}

.Artical_card3 .highlets_section .ant-collapse-header {
  background-color: #fbe8ce;
  border-radius: 6px;
  margin: 20px 0px;
}

.ant-collapse-item {
  margin-bottom: 20px;
}

.Artical_card3 .Tips_section .ant-collapse-header {
  background-color: #d8e8e6;
  border-radius: 6px;
  margin: 20px 0px;
}

@media (max-width: 767px) {

  .Artical_card1 .ant-card,
  .Artical_card2 .ant-card {
    width: 100%;
    max-width: unset;
  }
}

.ant-popover-inner {
  .gray_color {
    color: #939597;
  }
}

.sidebar {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
}

.is-sticky {
  position: fixed;
  top: 10px;
  z-index: 999;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

@media (min-width: 1024px) {
  .ant-layout-sider {
    display: none;
  }

  .ant-progress-inner {
    width: 100% !important;
    max-width: unset !important;
  }
}

.Artical_card1 .ant-card {
  max-width: 100%;
  width: 100%;
  margin-left: auto;
}

.Artical_card1 .ant-card .ant-card-body {
  padding: 15px 15px 10px 15px;
}

.Artical_card2 .border_align {
  border-bottom: 1px solid #ebebeb;
  padding: 0px 0px 15px 0px;
}

body {
  overflow-x: hidden;
}

.Artical_card3 .highlets_section .ant-collapse-header {
  background-color: #fbe8ce;
  border-radius: 6px;
}

.ant-collapse-item {
  margin-bottom: 20px;
}

.Artical_card3 .Tips_section .ant-collapse-header {
  background-color: #d8e8e6;
  border-radius: 6px;
}

.user_logo {
  position: relative;
}

.user_logo::after {
  position: absolute;
  border-right: 1px solid #ddd;
  height: 66px;
  content: "";
  right: 20px;
  top: 0px;
}

.sticky-thc {
  position: sticky;
  top: 0;
}

@media (max-width: 767px) {

  .Artical_card1 .ant-card,
  .Artical_card2 .ant-card {
    width: 100%;
    max-width: unset;
  }

  .text_center_right {
    text-align: left;
  }

  .sm_flex_section {
    display: grid !important;
    margin-bottom: 20px;
  }
}

.edit-body {
  display: grid;
  grid-template-rows: auto auto;
  height: 100%;
  width: 100%;
  background-color: aliceblue;
}

.button-resize {
  border-radius: 20px;
  width: 150px;
}

.edit-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fafbfd;
  padding: 100px 33px 20px;
}

.edit-head1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 100px 5px 20px;
}

.edit-head p {
  font-size: 2rem;
  line-height: 1.2;
  letter-spacing: -0.03rem;
}

.ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane {
  padding: 0px;
}

.nav-bar {
  display: block;
  padding-top: 20px;
  background-color: #fafbfd;
}

.nav-bar Button {
  color: rgb(89, 85, 85);
  width: 100%;
  border: none;
  background-color: #fafbfd;
  height: 50px;
  text-align: start;
  font-weight: bolder;
}

.nav-bar Button:hover {
  background-color: rgb(218, 220, 224);
}

.button-sty {
  color: white;
  border-radius: 20px;
  background-color: #ff5a5f;
  color: black;
}

.button-sty1 {
  width: 180px;
  border-radius: 20px;
  background-color: #e7e9ec;
}

.button-sty3 {
  border-radius: 20px;
  color: #e82112;
  border: 1px solid #e82112;
  padding: 0px 22px;
}

.button-sty2 {
  width: 180px;
  border-radius: 20px;
  background-color: #ff5a5f;
}

.final-body {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.input-sty Input {
  padding: 10px;
}

.input-sty .ant-form-item-label>label {
  font-size: 16px;
}

.login_section .ant-form-item-label>label {
  font-size: 16px;
}

.input-sty .ant-input {
  font-size: 15px;
}

.tab-sty {
  padding: 3rem;
  background-color: white !important;
}

.tab-sty2 {
  padding: 3rem;
  background-color: white !important;
}

.ant-tabs-left .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane {
  padding: 0px;
}

.tab-sty h6 {
  font-size: 22px;
  line-height: 1.1;
  padding: 30px 0px 30px 0px;
  font-weight: lighter;
  color: #122b46;
}

.tab-sty h3 {
  font-size: 17.6px;
  padding-bottom: 10px;
}

.tab-sty2 h6 {
  font-size: 22px;
  line-height: 1.1;
  padding: 30px 0px 30px 0px;
  font-weight: lighter;
  color: #122b46;
}

.tab-sty2 h3 {
  font-size: 17.6px;
  padding-bottom: 10px;
}

.Content-body {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  background-color: #fafbfd;
  padding: 0 32px;
}

.profile_bg .profile_sec figure {
  width: 190px;
}

.profile_bg .profile_sec img {
  margin: auto;
}

.profile_sec .ant-avatar-image {
  width: 180px;
  height: 180px;
}

.card-SavedPost .ant-card-body {
  display: flex;
  flex-direction: column;
  padding: 15px;
  width: 250px;
  height: 250px;
  justify-content: space-between;
}

.img-card-save {
  width: 100px;
  height: 100px;
}

.ant-image-preview-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  top: 19%;
  width: 54%;
  right: 24%;
  height: 74%;
  align-items: center;
}

.ant-tabs-tab-btn:hover {
  color: #ff5a5f;
}

.ant-tabs-tab-btn:focus {
  color: #4d5c6d;
}

.tab-style {
  padding: 20px;
}

@media (max-width: 735px) and (min-width: 220px) {
  .Content-body {
    width: 100%;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    margin-right: auto;
    margin-left: auto;
  }

  .tab-style {
    padding: 0px;
  }

  .Content-body {
    width: 100%;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    margin-right: auto;
    margin-left: auto;
    margin-top: 40px;
  }

  .Content-body1 .ant-tabs .ant-tabs-nav .ant-tabs-nav-list {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: end;
    padding-top: 10px;
  }

  .Content-body1 .ant-tabs-left>.ant-tabs-nav .ant-tabs-tab:hover {
    background-color: rgba(77, 92, 109, 0.1);
  }

  .menu-bar {
    margin: 0px 10px;
    display: flex;
    flex-direction: column;
  }

  .menu-bar1 {
    margin: 0px 10px;
    display: flex;
    flex-direction: column;
  }

  .Content-body1 .ant-tabs-left .ant-tabs-nav .ant-tabs-tab {
    padding: 8px 17px;
    text-align: center;
    font-size: 15px;
  }

  .tab-sty {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    background-color: white !important;
  }

  .tab-sty2 {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    background-color: white !important;
  }

  .Content-body1.ant-tabs-tab+.ant-tabs-tab {
    margin: 4px;
  }

  .nav-bar {
    display: flex;
    flex-direction: row;
  }

  .edit-head h1 {
    font-size: 1.5rem;
  }

  .main-tab {
    display: block;
  }

  tab {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 15px;
  }

  Input {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }

  .edit-head {
    display: flex;
    width: 100%;
  }
}

.Parent-body .ant-select {
  width: 100%;
}

.link-sty a {
  font-weight: bolder;
  color: #000;
  font-size: 13px;
  font: caption;
}

.collapse-sty {
  cursor: all-scroll;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.container {
  margin: 0 auto;
}

.moms_like_content {
  padding: 0 50px;

  h4 {
    font-size: 24px;
    margin: 15px 0;
    font-weight: bold;
  }

  .moms_like_list {
    margin-bottom: 0px;
    border-radius: 0px;

    li {
      border: 1px solid #ddd;
      border-bottom: 0px;
      padding: 25px;
      border-radius: 5px;
      border-radius: 0px;
      text-align: center;

      a {
        display: flex;
        flex-wrap: wrap;
        font-weight: bolder;
        font-size: 12px;
        color: #122b46;

        img {
          max-width: 100%;
          height: 35px;
        }

        span {
          align-self: center;
          font-size: 20px;
          color: #122b46;
          margin-left: 15px;
        }
      }

      a:hover {
        span {
          color: #ff5a5f;
        }
      }

      .moms_btn_grp {
        align-self: center;
        margin-left: auto;

        .pink_bttn {
          font-size: 12px;
          padding: 0 20px;
          line-height: 28px;
          font-weight: 400;
          background-color: #ff5a5f;
          border-radius: 50px;
          color: #fff;
          cursor: pointer;
          letter-spacing: 0.6px;
        }

        .pinkbtn {
          background-color: #ff5a5f;
          color: #fff;
          border: 0px;
        }

        button {
          margin-right: 5px;
        }

        button:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.table_content {
  padding: 5px 30px;
  border: 1px solid #e7e9ec;
  border-radius: 4px;
  margin-bottom: 15px;

  h4 {
    font-size: 18px;
    font-weight: 400;
    border-bottom: 1px solid #e7e9ec;
    padding: 15px 0;
  }

  .table_inner_cnt {
    padding: 20px 0 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);

    .ant-col {
      margin-bottom: 20px;

      h5 {
        font-size: 14px !important;
        font-weight: 400 !important;
        padding-bottom: 20px;
        text-transform: capitalize;
      }

      p {
        color: #4d5c6d;
        font-size: 14px;

        a {
          color: #ff5a5f;
          font-weight: bolder;
        }
      }
    }
  }

  .table_inner_cnt:last-child {
    border-bottom: none;
  }
}

.pink_outline_btn {
  background-color: #ff5a5f;
  border: 1px solid #ff5a5f;
  border-radius: 50px;
  color: #fff;
  padding: 6px 20px;
  font-size: 14px;
  font-weight: 500;
}

.pink_outline_btns {
  background-color: #ff5a5f;
  border: 1px solid #ff5a5f;
  border-radius: 50px;
  color: #fff;
  padding: 6px 20px;
  font-size: 14px;
  font-weight: 500;
}

.hdng_sec {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px 0;

  h3 {
    align-self: center;
    font-size: 30px;
  }
}

.ant-select-selector {
  height: auto !important;
}

.profile_bg {
  border: 1px solid #e7e9ec;
  position: relative;

  figure {
    background-color: #e2e9ef;
  }

  .prof_img {
    display: flex;
  }

  .profile_sec {
    margin: 0 34px 0 33px;
    margin-top: -5em;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    figure {
      background-color: #fff;
      padding: 5px;
      box-shadow: 0 2px 5px 0 rgb(18 43 70 / 12%), 0 0 0 1px #e7e9ec;
      border-radius: 5px;

      img {
        border-radius: 5px;
        max-width: 100%;
        height: 180px;
        object-fit: cover;
      }
    }

    h6 {
      font-size: 14px;
      align-self: center;
      padding: 0 15px;
      text-align: center;
    }
  }
}

.cover_bg_upload {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  opacity: 0;
}

.edit-icon {
  position: relative;
}

.edit-icon:hover .cover_bg_upload {
  opacity: 0.4;
}

img {
  max-width: 100%;
  height: 20px;
}

.mark_image {
  width: 40px;
  height: 40px;
  object-fit: cover;
  max-width: 40px;
  border-radius: 100%;
}

.inset-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.inside-tab {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px 0px;
  width: 100%;
  height: 200px;
  background-color: rgba(240, 243, 247, 0.5);
}

.capture-sty {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 10px;
}

.delete-tab p {
  padding-bottom: 20px;
}

.Capture-sty {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.name6 {
  color: #000 !important;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
}

.name:hover {
  color: #ff5a5f;
  font-weight: 600;
}

.final-body a {
  color: #ab0011 !important;
}

.values_section div {
  color: #000 !important;
}

.values_section div:hover {
  color: #ab0011 !important;
}

a {
  font-weight: bold;
}

.p-sty {
  color: #b91c1c;
}

.span-sty {
  color: white;
  background-color: #05d786;
  padding: 20px;
  align-items: center;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn:active {
  color: #000;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn:focus {
  color: #000;
}

.mark_image {
  width: 40px;
  height: 40px;
  object-fit: cover;
  max-width: 40px;
  border-radius: 100%;
}

.ant-popover {
  position: fixed;
}

.notify_tab .ant-tabs-nav-wrap {
  justify-content: end;
  margin-right: 20px;
}

.notify_tab .ant-tabs-top>.ant-tabs-nav::before {
  border-bottom: 0px;
}

.notify_tab .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ab0011 !important;
}

.inside-sty .img-sty {
  width: 130px;
  height: 200px;
  border-radius: 150px;
  border: #000 dashed 1px;
}

.inside-sty {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.p-sty {
  color: #b91c1c;
}

.span-sty {
  color: white;
  background-color: #05d786;
  border-radius: 5px;
  padding: 8px;
  width: 100%;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn:active {
  color: #000;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn:focus {
  color: #000;
}

.p-cov {
  color: #856404;
  background-color: #fff3cd;
  border-radius: 5px;
  padding: 8px;
  width: 100%;
  margin-top: 10px;
}

@media (max-width: 734px) {
  .profile_name {
    align-items: center;
  }
}

@media (max-width: 550px) {
  .inside-tab {
    margin: 0px;
  }
}

@media (max-width: 650px) {
  .ant-space {
    display: block;
  }

  .inside-sty .img-sty {
    width: 100px;
    height: 130px;
  }

  .capt-sty {
    flex-direction: column;
  }
}

@media (max-width: 735px) {
  .ant-tabs-left>.ant-tabs-nav .ant-tabs-tab:hover {
    background-color: rgba(77, 92, 109, 0.1);
  }

  .profile_sec {
    padding: 0 17px 20px;
    margin-top: -10em;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 400px) {
  .button-sty {
    font-size: 10px;
  }

  .ant-tabs-left>.ant-tabs-nav .ant-tabs-tab:hover {
    background-color: rgba(77, 92, 109, 0.1);
  }
}

.ant-tabs-left>.ant-tabs-nav .ant-tabs-tab:hover {
  background-color: rgba(77, 92, 109, 0.1);
}

.ant-tabs-left>.ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active {
  background-color: rgba(77, 92, 109, 0.1);
}

.ant-tabs-tab-btn {
  font-size: 15px;
}

.link-sty a {
  font-weight: bolder;
}

.ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap {
  overflow: inherit;
}

Table {
  background-color: rgba(77, 92, 109, 0.1);
  margin-bottom: 15px;
}

tr.ant-table-expanded-row>td:hover {
  background-color: white;
}

tr.ant-table-expanded-row>td {
  background-color: white;
}

#file-upload-button {
  background-color: #ab0011;
}

.send_message .ant-input {
  width: 100% !important;
}

.ant-modal-centered {
  background: rgba(250, 251, 253, 0.9) !important;
}

.Card-moms .ant-card-body {
  padding: 0 24px 24px 24px !important;
}

.members {
  font-size: 12px;
  text-align: left;
}

.privacy_table .ant-table table {
  background-color: #fff;
  border: 1px solid rgb(188, 201, 215);
}

.privacy_table .ant-table-pagination.ant-pagination {
  display: none;
}

.account_tabs .ant-tabs>.ant-tabs-nav {
  background: #fbfbfc !important;
  padding-top: 30px;
}

.account_tabs .ant-tabs {
  border: 1px solid rgb(203 213 225);
  border-radius: 5px;
}

.account_tabs .ant-tabs-left>.ant-tabs-content-holder>.ant-tabs-content>.ant-tabs-tabpane {
  padding: 0 !important;
}

.email_radio .ant-radio-inner::after {
  background-color: #ff5a5f !important;
}

.email_radio .ant-radio-checked .ant-radio-inner {
  border-color: #d9d9d9 !important;
}

.login_section .anticon svg {
  background-color: white !important;
  color: #007cff !important;
  border-radius: 20px !important;
  margin-top: 6px !important;
}

.input_search .ant-input {
  padding: 15px 10px !important;
}

.input_search .ant-input-search-button {
  height: 54px;
}

.send_message .ant-input {
  width: 100% !important;
}

.ant-modal-centered {
  background: rgba(250, 251, 253, 0.9) !important;
}

.input-search-sty {
  border-radius: 30px;
  font-size: 16px;
  height: 40px;
  width: 200px;
}

.button-input {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  gap: 13px;
}

.modal-sty p {
  font-size: 16px;
  padding: 10px 0;
}

.ant-upload.ant-upload-select-picture-card {
  width: 100% !important;
  display: contents;
}

.image-style img {
  border-radius: 50%;
}

.message_chat .ant-card-body {
  text-align: center;
  padding: 10px 28px 10px 20px !important;
}

.p-tags {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 10px;
}

.p-tag {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 10px;
}

.p-tags a {
  background-color: #ff5a5f;
  color: #ddd;
}

.card-sty {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 10px;
}

.card-sty1 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
}

.select-sty {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 20px;
  column-gap: 10px;
  margin-bottom: 20px;
}

.meta-sty a {
  color: #ff5a5f;
}

.avatar-sty {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 15px;
}

.p-tagSty {
  font-size: 12px;
}

.image-p {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ant-comment-inner {
  display: flex;
  gap: 10px;
}

.avata-bg {
  background-color: #ddd;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
  text-align: center;
  padding: 10px;
}

.comment {
  margin: 10px 0;
}

.ant-tabs-nav-list {
  padding-top: 0;
}

.forum-p {
  font-size: 22px;
  font-weight: bolder;
  padding: 6px;
}

.table_content1 {
  padding: 5px 17px;
  border: 1px solid #e7e9ec;
  border-radius: 4px;
}

.ant-tabs-content {
  display: block;
}

.headng_sec {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px 0;
}

.modal-sty .ant-modal-footer {
  display: none;
}

.icon-sty {
  background-color: rgb(239, 239, 239);
  color: black;
  margin: 10px;
  outline: 10px;
  border: 1px solid rgb(237, 229, 229);
  border-radius: 50px;
}

.icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 0.5px solid rgb(235, 227, 227);
  border-radius: 5px;
  gap: 5px;
}

.icon2 {
  border: 0.5px solid rgb(237, 230, 230);
  background-color: blue;
}

.badge {
  -ms-transform: rotate(20deg);
  transform: rotate(20deg);
  width: 10px;
}

.discuss {
  padding: 16px;
  border: 1px solid rgb(179, 176, 176);
  display: flex;
  flex-direction: row;
  gap: 19px;
  align-items: center;
}

.reply {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.a-tag-sty {
  font-size: 8px;
  padding: 3px;
  height: 17px;
  border-radius: 5px;
  background-color: cadetblue;
  color: white;
}

.inside-tab input[type="file"]::file-selector-button {
  color: white;
  background-color: #ff5a5f !important;
  border-radius: 5px;
  height: 30px;
  width: 182px;
  border-color: #ff5a5f !important;
}

input {
  width: 182px;
}

.delete-sty {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding-bottom: 10px;
}

.ant-card-small>.ant-card-head {
  background-color: #e7e9ec;
  margin: 10px 5px;
}

textarea.ant-input:focus {
  border: none;
  height: 4rem;
}

.Space-sty {
  display: flex;
}

.table_content2 {
  margin-bottom: 15px;
}

.ant-table-pagination.ant-pagination {
  display: none;
}

.ant-popover {
  position: fixed !important;
}

.menu-nav {
  display: flex;
  justify-content: space-between;
}

.menu-item {
  color: #fcc;
  padding: 3px;
}

.three-dots:after {
  cursor: pointer;
  color: #444;
  content: "\2807";
  font-size: 20px;
  padding: 0 5px;
}

a {
  text-decoration: none;
  color: white;
}

a div {
  padding: 2px;
}

.dropdown {
  position: absolute;
  right: 10px;
  background-color: white;
  outline: none;
  opacity: 0;
  z-index: -1;
  max-height: 0;
}

.dropdown span:hover {
  background-color: rgba(77, 92, 109, 0.1);
}

.dropdown-container:focus {
  outline: none;
}

.dropdown-container:focus .dropdown {
  opacity: 1;
  z-index: 100;
  max-height: 100vh;
}

.p-cover {
  border-radius: 50px;
  background-color: #ff5a5f;
  padding: 2px;
  text-align: center;
  color: white;
  font-size: 10px;
  width: 50px;
  height: 18px;
}

.radio-sty {
  width: 262px;
  height: 74.86px;
}

.ant-form-vertical .ant-form-item {
  width: 100%;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height: 46px;
}

.ant-picker {
  width: 100%;
}

.badge-style {
  padding-left: 60px;
  padding-right: 5px;
}

.badge-style2 {
  padding-left: 100px;
  padding-right: 5px;
}

.messag .ant-input-affix-wrapper {
  border-radius: 100px !important;
  background-color: #fbfbfc;
}

.modal-sty-1 .ant-modal-content {
  top: 150px !important;
  border-radius: 10px !important;
  height: 230px !important;
}

.modal-sty-1 .ant-modal-header {
  border-radius: 10px !important;
}

.modal-sty-1 .ant-modal-footer {
  display: none;
}

.card_chat .ant-skeleton-content .ant-skeleton-paragraph>li {
  height: 5px !important;
}

.card_chat .ant-skeleton-content .ant-skeleton-title {
  height: 5px !important;
}

.card_chat .ant-skeleton-content .ant-skeleton-title+.ant-skeleton-paragraph {
  margin-top: 10px !important;
}

.message_chat .ant-card-body:hover {
  background: #f5f6f7 !important;
}

.message_para:hover {
  color: #ff5a5f !important;
}

.messag .ant-card-bordered {
  border: none !important;
}

@media (max-width: 767px) {
  .moms_like_content {
    padding: 0 20px;

    h4 {
      font-size: 16px;
      margin: 15px 0;
      font-weight: bold;
    }
  }

  .badge-style {
    padding-left: 10px;
  }

  .badge-style2 {
    padding-left: 10px;
  }

  .container {
    padding: 0px 15px !important;
  }
}

@media (max-width: 590px) {
  .Pincode-sty {
    display: block;
    flex-wrap: wrap;
  }
}

.privacy_section .ant-tabs-ink-bar {
  width: 0px !important;
}

::-webkit-scrollbar {
  width: 2px;
  height: 4px;
}

a:hover,
.ant-menu-submenu-title:hover,
.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-submenu-selected {
  color: #fca5a5 !important;
}

.ant-layout-header p {
  font-weight: 600 !important;
  color: black;
  font-size: 17px;
  font-weight: bold;
}

.ant-layout-header p:hover {
  font-weight: 600 !important;
  color: #fca5a5 !important;
}

::-webkit-scrollbar-thumb {
  background: #ff5a5f;
}

@media (max-width: 443px) {
  .menu-bar1 .ant-tabs .ant-tabs-nav .ant-tabs-nav-list {
    flex-wrap: nowrap !important;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-bottom: 20px;
    flex-direction: row;
  }
}

@media only screen and (max-width: 768px) {
  .privacy_section .ant-tabs>.ant-tabs-nav .ant-tabs-nav-list {
    flex-direction: initial !important;
    color: #ff5a5f !important;
  }

  .privacy_section .ant-tabs {
    display: grid;
  }

  .privacy_section .ant-tabs>.ant-tabs-nav .ant-tabs-nav-list {
    flex-direction: initial !important;
    color: #ff5a5f !important;
  }

  .privacy_section .anticon svg {
    display: none !important;
  }

  .moms_like_content .ant-tabs>.ant-tabs-nav .ant-tabs-nav-list {
    flex-direction: initial !important;
    color: #ff5a5f !important;
  }

  .moms_like_content .ant-tabs {
    display: grid;
  }

  .moms_like_content .ant-tabs>.ant-tabs-nav .ant-tabs-nav-list {
    flex-direction: initial !important;
    color: #ff5a5f !important;
  }

  .moms_like_content .anticon svg {
    display: none !important;
  }

  .tab-sty .ant-tabs>.ant-tabs-nav .ant-tabs-nav-list {
    flex-direction: initial !important;
    color: #ff5a5f !important;
  }

  .tab-sty .ant-tabs {
    display: grid;
  }

  .tab-sty .ant-tabs>.ant-tabs-nav .ant-tabs-nav-list {
    flex-direction: initial !important;
    color: #ff5a5f !important;
  }

  .tab-sty .ant-tabs-ink-bar .ant-tabs-ink-bar-animated {
    width: 0px;
  }

  .tab-sty .anticon svg {
    display: none !important;
  }

  .account_tabs .ant-tabs>.ant-tabs-nav {
    padding-top: 0px;
  }

  .email_paragraph {
    width: 100%;
    max-width: 235px;
  }

  .privacy_section .ant-tabs .ant-tabs-nav .ant-tabs-nav-list {
    flex-wrap: nowrap !important;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-bottom: 20px;
  }

  .menu-bar .ant-tabs .ant-tabs-nav .ant-tabs-nav-list {
    flex-wrap: nowrap !important;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-bottom: 20px;
    flex-direction: row;
  }

  .moms_like_content .ant-tabs .ant-tabs-nav .ant-tabs-nav-list {
    flex-wrap: nowrap !important;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-bottom: 20px;
    flex-direction: row;
  }

  .button-input {
    display: flex;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 768px) {
  .moms_like_content .ant-tabs {
    display: flex;
    flex-direction: column;
    padding-left: 0px;

    .ant-tabs .ant-tabs-nav .ant-tabs-nav-list {
      flex-wrap: nowrap !important;
      overflow-x: scroll;
      overflow-y: hidden;
      flex-direction: row;
    }
  }

  .tab-sty .ant-tabs>.ant-tabs-nav .ant-tabs-nav-list {
    flex-direction: initial !important;
    color: #ff5a5f !important;
  }

  .tab-sty .ant-tabs .ant-tabs-nav .ant-tabs-nav-list {
    flex-wrap: nowrap !important;
    overflow: scroll;
    padding-bottom: 10px;
  }

  .menu-bar .ant-tabs {
    display: flex;
    flex-direction: column;
    padding-left: 0px;

    .ant-tabs .ant-tabs-nav .ant-tabs-nav-list {
      flex-wrap: nowrap !important;
      overflow-x: scroll;
      overflow-y: hidden;
      padding-bottom: 20px;
      flex-direction: row;
    }
  }

  .menu-bar1 .ant-tabs {
    display: flex;
    flex-direction: column;
    padding-left: 0px;

    .ant-tabs .ant-tabs-nav .ant-tabs-nav-list {
      flex-wrap: nowrap !important;
      overflow-x: scroll;
      overflow-y: hidden;
      padding-bottom: 20px;
      flex-direction: row;
    }
  }

  .menu-bar .ant-tabs-left>.ant-tabs-content-holder>.ant-tabs-content>.ant-tabs-tabpane {
    padding-left: 0px;
  }

  .menu-bar1 .ant-tabs-left>.ant-tabs-content-holder>.ant-tabs-content>.ant-tabs-tabpane {
    padding-left: 0px;
  }

  .ant-progress-inner {
    max-width: unset !important;
    min-width: unset !important;
  }
}

.Pincode-sty .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height: 43px;
}

.Parent-body .ant-picker {
  height: 43px;
}

.Parent-body .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height: 43px;
}

.tab-sty .ant-tabs>.ant-tabs-nav .ant-tabs-nav-more {
  display: none;
}

::-webkit-scrollbar {
  width: 2px;
  height: 4px;
}

.table-sty1 .anticon[tabindex] {
  float: right;
}

.ant-tabs-left>.ant-tabs-nav .ant-tabs-ink-bar {
  background-color: #b30000;
}

.ant-tabs-top>.ant-tabs-nav .ant-tabs-ink-bar-animated {
  background-color: #b30000;
}

.tab-sty .ant-tabs-top>.ant-tabs-nav .ant-tabs-ink-bar {
  width: 0px;
}

.menu-bar {
  border: 1px solid #e7e9ec;
}

.menu-bar .ant-tabs-left>.ant-tabs-content-holder>.ant-tabs-content>.ant-tabs-tabpane {
  padding-left: 0px;
}

.menu-bar1 .ant-tabs-left>.ant-tabs-content-holder>.ant-tabs-content>.ant-tabs-tabpane {
  padding-left: 0px;
}

.input-button-sty {
  border: none;
}

.p-cover {
  border-radius: 50px;
  background-color: #ff5a5f;
  padding: 2px;
  text-align: center;
  color: white;
  font-size: 10px;
  width: 50px;
  height: 18px;
}

.radio-sty {
  width: 262px;
  height: 74.86px;
}

.ant-form-vertical .ant-form-item {
  width: 100%;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height: 46px;
}

.ant-picker {
  width: 100%;
}

.Moms_Following_list .badge>.badge-style {
  padding-left: 10px;
  margin-left: 0px;
}

.badge>.badge-style {
  padding: 5px 10px;
  margin-left: 9px;
  color: #fff;
}

.badge>.badge-style2 {
  padding-left: 100px;
  padding-right: 5px;
}

@media (max-width: 767px) {
  .badge>.badge-style {
    padding-left: 10px;
  }

  .badge>.badge-style2 {
    padding-left: 10px;
  }
}

.but-sty {
  border-radius: 20px !important;
  background-color: #ff5a5f !important;
  color: white !important;
}

.Pincode-sty .ant-select-selector {
  height: 44px !important;
}

.ant-radio-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 5px;
  flex-wrap: wrap;
}

.radio-custom .ant-radio-button-wrapper img {
  width: 77px;
  height: 52px;
}

.radio-custom .ant-radio-button+* {
  display: block;
  padding: 0;
  overflow: hidden;
  border-radius: 8px;
  overflow: hidden;
}

.radio-custom .ant-radio-button-wrapper {
  border: 2px solid #ccc;
  border-radius: 8px !important;
  padding: 4px;
  width: 262px;
  height: 68px;
  margin: 10px;
}

.radio-custom .ant-radio-button-wrapper-checked:focus-visible {
  border: 2px solid#FF5A5F;
  border-radius: 8px;
  padding: 4px;
  width: 262px;
  height: 75px;
}

.radio-custom .ant-radio-button-wrapper-checked {
  border: 2px solid#FF5A5F;
  border-radius: 8px;
  width: 262px;
  height: 75px;
  margin: 10px;
}

.radio-custum .ant-radio-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
  flex-wrap: wrap;
}

@media only screen and (max-width: 779px) {
  .lg-menu {
    display: none;
  }
}

@media only screen and (min-width: 780px) {
  .sm-menu {
    display: none;
  }
}

.ant-drawer-close {
  position: absolute;
  right: 0px;
}

button.pink_button {
  padding: 0px 80px;
  text-align: center;
  border-radius: 30px;
  background-color: #ff5a5f;
  color: #fff;
}

.panel_close .ant-collapse-header,
.panel_close,
.panel_close .ant-collapse-item,
.panel_close .ant-collapse-content {
  background-color: #fff;
  padding: 0px !important;
  border: 0px !important;
}

.Content-body1 .badge .badge-style {
  background: #d6d6d6 !important;
  transition: 0.2s ease-in;
  border-radius: 30%;
}

.badge:hover .ant-badge-count {
  background: rgb(194, 77, 77) !important;
  font-weight: 700;
}

.Content-body1 .ant-tabs-tab-active .badge-style {
  background: #ff4d4f !important;
  border-radius: 30%;
}

.member-status {
  background: #ff5a5f;
  color: white !important;
  padding: 5px 20px;
  border-radius: 20px;
}

.member-status:hover {
  background: #ff4d4f;
  color: white !important;
}

.ant-list-split .ant-list-item:last-child {
  border-bottom: 1px solid #f0f0f0 !important;
}

.folder .anticon svg {
  display: block;
}

.privacy_section .ant-input-affix-wrapper:active {
  background-color: #e8f0fe;
}

.privacy_table .ant-table-tbody tr:nth-child(odd) {
  background-color: #e8f0fe;
}

.sent_table .ant-table-tbody tr:hover {
  background-color: #fff !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.sent_table .ant-table-tbody tr {
  background-color: #fff !important;
}

.sent_table .ant-table-thead th {
  background-color: #fff !important;
}

.chat-deleter {
  position: absolute;
  right: 0px;
  opacity: 0;
  font-size: 20px;
  cursor: pointer;
  transition: 0.5s ease-in-out;
  color: rgb(135, 139, 143);
  font-size: 30px;
}

.ant-card:hover .chat-deleter {
  opacity: 1;
}

.gif-modal {
  height: 320px;
  width: 300px;
  background-color: rgb(247, 251, 252);
}

.radio-custom .ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper {
  border: 2px solid#FF5A5F;
  border-radius: 8px;
  padding: 4px;
  width: 262px;
  height: 75px;
  color: #ff5a5f;
  border-right-color: #ff5a5f;
}

.radio-custom .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #ff5a5f;
}

.radio-custom .ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-chil .search_feild_section input {
  border: 0px;
  padding: 10px 50px;
}

.search_feild_section .ant-input-group-addon {
  border: 0px;
  position: absolute;
  left: 0px;
  border: 0px;
  top: 3px;
  z-index: 1000;
}

.search_feild_section .ant-input-group-addon button {
  border: 0px;
  width: 25px;
  height: 30px;
  margin: 4px;
}

.search_feild_section .ant-input-group .ant-input {
  padding-left: 36px;
}

.header_main_menu .ant-menu-submenu-arrow {
  display: none;
}

li.ant-menu-item:last-child {
  margin-bottom: 15px !important;
}

.ant-menu-title-content {
  color: #000;
}

.ant-menu-title-content:hover {
  color: #ff5a5f !important;
}

.image_upload .ant-upload.ant-upload-select-picture-card>.ant-upload {
  background-color: rgba(240, 243, 247, 0.5);
}

.Pincode-stys .ant-form-item-control {
  margin-right: 20px !important;
}

.view_myprofile p {
  font-size: 14px !important;
  margin-left: 7px !important;
}

.capt-sty .ReactCrop {
  height: 211px !important;
  width: 278px !important;
}

.image_logo {
  border-radius: 100%;
  object-fit: cover;
  width: 100%;
  max-width: 90px;
  margin: 0 auto;
  height: 90px;
}

.card-sty .ant-card-cover {
  padding: 10px 0px 0px 0px;
}

span.follow_section {
  color: #939597 !important;
}

.tag-sty .ant-tag .ant-tag-has-color {
  background-color: rgb(77, 92, 109);
  padding: 5px;
  font: caption;
  margin: 24px;
}

.ant-card-meta-detail {
  text-align: center;
}

.anticon .anticon-close .ant-tag-close-icon {
  margin-bottom: -3px;
}

.autocomplete .ant-select-selector {
  border: none !important;
  margin: 26px !important;
}

.input-send.ant-input:placeholder-shown {
  border: none !important;
}

.head-profile-pic {
  border-right: 1px solid rgba(0, 0, 0, 0.1) !important;
  height: 30px;
}

.pink_text {
  color: #ff5a5f;
  letter-spacing: 0.25px;
  line-height: 1rem;
}

.pink_text1 {
  font-size: 0.75rem;
  font-weight: 600;
  color: #122b46;
  letter-spacing: 0.25px;
  line-height: 1rem;
  text-transform: uppercase;
}

.text-area-input {
  background-color: #fbfbfc;
  border-bottom: 1px solid #fbfbfc;
  height: 60px;
}

.text-area-placeholder.ant-input:placeholder-shown {
  padding-top: 12px;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;

}

.text-area-placeholder {
  height: 80px !important;
  border: none !important;
  resize: none !important;
  box-shadow: none !important;
}

.profile-head-name {
  margin: 0 10px 0 0;
  word-break: break-word;
  font-size: 2rem;
  position: relative;
  color: #383e41;
  text-shadow: none;
}

.profile-head-tag {
  background: #d94c61;
  border-radius: 100px;
  font-size: 11px;
  font-weight: 800;
  color: #fff;
  letter-spacing: 0.25px;
  text-align: center;
  display: inline-block;
  padding: 4px 10px 4px;
  line-height: 1;
}

.radio-custom .ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child {
  border-right-color: #ff5a5f;
}

.notify {
  color: #383e41;
}

.message-input .ant-input {
  background-color: #fbfbfc;
}

.card-message {
  background: #f5f6f7;
}

.name7:hover {
  color: #ff5a5f;
}

.form-message .ant-form {
  border-top: 1px solid #e7e9ec;
}

.follower-profile {
  color: #a3a5a9;
  font-size: 14px;
  align-self: center;
}

.Card-moms:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.Card-moms .ant-card-meta-title {
  color: #122b46;
  margin-bottom: 6px;
  margin-top: 0;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: -0.21px;
  font-style: normal;
}

.Card-moms .ant-card-meta-title:hover {
  color: #ff5a5f;
}

.Card-moms .ant-card-meta-description {
  color: #212429;
  letter-spacing: -0.24px;
  line-height: 1.3;
  font-size: 12px;
  font-weight: bolder;
}

.profike_imgs {
  padding: 32px;
}

@media (max-width: 734px) {
  .profike_imgs {
    padding: 41px;
  }
}

.Content-body1 {
  border: 1px solid #e7e9ec;
  margin: 0 32px;
}

@media (max-width: 768px) {
  .menu-bar .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-ink-bar {
    display: none;
  }

  .moms_like_content .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-ink-bar {
    display: none;
  }

  .menu-bar1 .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-ink-bar {
    display: none;
  }

  .Content-body .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list {
    border: none;
  }

  .notify-head {
    padding-top: 80px;
  }
}

.tab-sty .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list {
  border: none;
}

.upload-list-inline .ant-upload-list-item {
  margin-right: 15px;
}


.ant-upload-list-picture {
  display: flex;
  // width: 600px;
  flex-wrap: wrap;
}

.ant-upload-list-item {
  z-index: 1;
}

.ant-popover {
  position: fixed !important;
}

.ant-select-selection-item {
  padding: 5px 0px !important;
}

.cover_bg {
  width: 100%;
}

.h_400 {
  height: 250px;
  overflow-y: scroll;
}

span.black_text {
  color: #000;
}

.Artical_card3 .Liked_post_section .ant-card-body {
  padding: 15px !important;
}

.ant-btn[disabled] {
  opacity: 0.7 !important;
}

.ant-picker-input:hover .ant-picker-clear {
  pointer-events: none;
  display: none;
}

.ant-upload-list {
  // padding: 15px 0px !important;
}
.ant-upload-list-picture .ant-upload-list-item, .ant-upload-list-picture-card .ant-upload-list-item {
  /* position: relative; */
  height: 66px;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  width: 100px !important;
  margin: 5px !important;
}

.ant-upload-list {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  line-height: 1.5715;
  width: 105px !important;
}

.ant-upload-list-picture.ant-upload-list-item {
  z-index: 0 !important;
}

.ant-layout .group-content {
  display: contents;
}

.horizontal-scroll-container {
  overflow-x: auto;
}

.horizontal-scroll-container-minicard {
  overflow-x: auto;
  cursor: pointer;
}

.horizontal-scroll-container::-webkit-scrollbar {
  display: none;
}

.scrollable-container {
  display: flex;
  overflow-x: auto;
}

.scrollable-container::-webkit-scrollbar {
  display: none;
}

.p-card-container {
  border-radius: 8px !important;
  width: 90%;
  position: relative;
  background-color: #fff;
  border: 1px solid #f0f0f0;
}

.p-card-img {
  width: 100%;
  border-radius: 8px 8px 0 0 !important;
}

.p-card-container img {
  height: 100%;
  width: 100%;
}

.p-card-container p {
  color: var(--gray-gray-2, #666);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0.48px;
}

.p-card-container button {
  display: flex;
  width: 68px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: var(--primary-red-2, #ed4d41);
  cursor: pointer;
  color: white;
  border: none;
}

.p-card-container button:hover {
  display: flex;
  width: 68px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: var(--primary-red-2, #ed4d41);
  cursor: pointer;
  color: white;
  border: none;
}

.p-card-container h3 {
  color: var(--gray-gray-1, #333);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.48px;
  align-self: stretch;
}

.a-card-container {
  border-radius: 8px !important;
  width: 100%;
  position: relative;
  border: none;
}

.ads-card {
  border: none !important;
  gap: 20px;
}

.a-card-container img {
  height: 100%;
  width: 100%;
  flex-shrink: 0 !important;
  border-radius: 8px;
}

.a-card-img {
  width: 100%;
  flex-shrink: 0 !important;
}

.a-card-container p {
  color: var(--gray-gray-2, #666);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0.48px;
}

.a-card-container button {
  display: flex;
  width: 98px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: var(--primary-red-2, #ed4d41);
  cursor: pointer !important;
  color: white;
  border: none;
}

.a-card-container button:hover {
  display: flex;
  width: 98px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: var(--primary-red-2, #ed4d41);
  cursor: pointer !important;
  color: white;
  border: none;
}

.a-card-container h3 {
  color: var(--gray-gray-1, #333);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.48px;
  align-self: stretch;
}

.s-card-container {
  margin-right: 20px;
  border-radius: 8px !important;
  width: 100%;
}

.sds-card {
  border: none !important;
  border-radius: 8px !important;
  background-color: #fff;
  width: 100%;
  text-align: center;
}

.s-card-container img {
  width: 100%;
  border-radius: 8px 8px 0 0 !important;
}

.s-card-img {
  width: 100%;
}

.s-card-container p {
  color: var(--gray-gray-2, #666);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0.48px;
}

.s-card-container button {
  display: flex;
  width: 98px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: var(--primary-red-2, #ed4d41);
  cursor: pointer !important;
  color: white;
}

.s-card-container button:hover {
  display: flex;
  width: 98px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: var(--primary-red-2, #ed4d41);
  cursor: pointer !important;
  color: white;
  border: none;
}

.s-card-container h3 {
  color: var(--gray-gray-1, #333);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.48px;
  align-self: stretch;
}

.ellipsis-text {
  display: -webkit-box;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis-title {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.line {
  border: 1px solid #f0f0f0;
  border-left: none;
  border-right: none;
}

.ant-collapse {
  border: none !important;
  background-color: white !important;
}

.ant-collapse-content>.ant-collapse-content-box {
  padding: 0px !important;
  text-align: justify;
}

.react_calendar_card {
  box-shadow: 20px 20px 50px 15px #f0f0f0;
  border-radius: 18px !important;
  background-color: #f0f0f0;
  width: 100%;
}

.react_calendar_card p {
  margin-top: 20px;
  text-align: center;
  gap: 12px;
  font-size: 21px;
  width: 60%;
  margin-left: 20%;
  padding-bottom: 10px;
}

.react_calendar_card_in {
  box-shadow: 20px 20px 50px 15px #f0f0f0;
  border-radius: 18px !important;
  background-color: #fff;
  text-align: center;
}

.claendar_btn {
  border-radius: 50% !important;
  height: 50px !important;
  width: 50px !important;
  border: none !important;
  font-weight: bold !important;
  background-color: #ed4d41 !important;
  color: white !important;
  margin-bottom: 20px;
}

.react-calendar {
  width: 100% !important;
  border: none !important;
}

.slider-container {
  width: 100%;
  overflow: hidden;
}

.slider-scrollable {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.slider-scrollable::-webkit-scrollbar {
  display: none;
}

.s-card-scroll::-webkit-scrollbar {
  display: none;
}

.s-card-scroll {
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.slider-number {
  margin: 5px;
  font-size: 16px;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.slider-number.selected {
  background-color: #f4b619;
  border-radius: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  height: 70px;
}

.carousal_testing h3 {
  margin: 0;
  height: 160px;
  color: #fff;
  line-height: 160px;
  text-align: center;
  background-color: #05d786;
}

.carousel-cal-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  background-color: transparent !important;
  border: none !important;
  gap: 10px;
}

.carousel-cal-buttons button {
  width: 90%;
  border-radius: 55px;
  background-color: white;
  height: 50px;
  font-weight: bold;
}

.carousel-cal-buttons img {
  width: 10%;
}

.carousel-container {
  max-width: 600px;
  margin: 0 auto;
  position: relative;
}

.carousel-item {
  padding: 20px;
  text-align: center;
  font-size: 24px;
}

.carousel-navigation {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  padding: 10px;
}

.carousel-button-left,
.carousel-button-right {
  flex: 1;
  display: flex;
  justify-content: center;
}

.carousel-button-right {
  justify-content: flex-end;
}

.carousel-button-left {
  justify-content: flex-start;
}

.custom-selected-tile {
  background-color: red !important;
  color: #05d786 !important;
}

.selected {
  background-color: #f4b619 !important;
}

.period {
  background-color: #f6a6a0 !important;
  border-radius: 50% !important;
}

.ovulation {
  background-color: #91e5bd !important;
  border-radius: 50% !important;
}

.fertileWindow {
  background-color: #d3f4ea !important;
  border-radius: 50% !important;
}

.implantation {
  background-color: #0084f4 !important;
  border-radius: 50% !important;
}

.react-calendar__tile--now {
  background-color: transparent !important;
  color: #000 !important;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 24px 6.6667px !important;
}

.custom-chart .recharts-cartesian-grid-line-vertical {
  display: none !important;
}

.recharts-bar-rectangle {
  border-radius: 20px 20px 20px 20px !important;
  border: 1px solid red !important;
}

link {
  text-decoration: none !important;
}

a:link {
  text-decoration: none !important;
}

a:visited {
  text-decoration: none !important;
}

a:hover {
  text-decoration: none !important;
}

a:active {
  text-decoration: none !important;
}

.link-no-underline {
  text-decoration: none;
}

.cars {
  height: 160px;
  color: #fff;
  line-height: 160px;
  text-align: center;
  background-color: #364d79;
}

.content-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.slick-dots li button:before {
  color: #ed4d41 !important;
  font-family: "slick";
  font-size: 12px !important;
  line-height: 30px;
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 30px;
  content: "•" !important;
  text-align: center;
  opacity: 0.25;
}

.ant-carousel .slick-dots li button:before {
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•" !important;
  text-align: center;
  opacity: 0.25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev,
.ant-carousel .slick-next {
  position: absolute;
  top: 0% !important;
  display: block;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  padding: 0;
  color: transparent;
  font-size: 0;
  line-height: 0;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
}

.slick-dots {
  position: static !important;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.ant-collapse>.ant-collapse-item {
  border: none !important;
}

content>.ant-collapse-content-box {
  padding: 17px !important;
}

.full-btn {
  width: 100%;
  background-color: #ed4d41;
  border-radius: 4px;
  color: white;
  font-weight: bold;
  padding: 6px 8px;
  font-size: 16px;
}

.tetetet {
  padding: 16px;
}

.custom-scrollbar {
  scrollbar-width: none !important;
  -ms-overflow-style: none !important;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 0 !important;
  display: none !important;
}

.group_action li {
  cursor: pointer;
  padding: 4px;
  font-size: 16px;
}

.group_action li:hover {
  background-color: #dddddd;
}

.group_profile_left p {
  font-weight: 16px;
  color: #959191;
}

.group_profile_left h1 {
  font-size: 24px;
}

.comment-container {
  display: flex;
  align-items: flex-start;
  margin: 10px;
  border-radius: 7px;
}

.chat-container {
  display: flex;
  align-items: flex-start;
  margin: 10px;
  border-radius: 7px;
  background-color: #fad3d0;
}

.chat-message {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.message-box {
  padding: 8px 12px;
  border-radius: 8px;
  margin-bottom: 5px;
  max-width: 70%;
}

.sender .message-box {
  background-color: #fad3d0;
  align-self: flex-end;
}

.receiver .message-box {
  background-color: #d7dbd9;
  align-self: flex-start;
}

.message-time {
  color: #999;
  font-size: 12px;
  align-self: flex-end;
}

.receiver .message-time {
  align-self: flex-start;
}

.comment-container-left {
  background-color: #d7dbd9;
  display: flex;
  align-items: flex-start;
  margin: 10px;
  border-radius: 7px;
  width: auto;
}

.comment-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}

.comment-image-reply {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 8px;
}

.comment-text {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: auto;
}

.comment-input {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  width: 100%;
  padding: 15px;
}

.comment-scrollable {
  max-height: 500px;
  overflow-y: auto;
}

.no-padding-modal .ant-modal-body {
  padding: 0 !important;
}

.ant-modal-header {
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.85) !important;
  background: #ededed;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
}

.comment-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.comment-field {
  flex: 1;
}

.ant-menu-vertical {
  border-right: none !important;
}

.ant-popover-inner-content {
  max-width: none !important;
  width: auto !important;
  overflow: hidden !important;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

.ant-popover-inner-content {
  padding-right: 0px !important;
  padding-bottom: 0px !important;
  height: auto !important;
  overflow: hidden;
  overflow: hidden !important;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

.ant-popover-inner {
  overflow: hidden !important;
  height: auto;
}

link p {
  color: black;
}

.global_date {
  color: #959191;
}

.read-more-header p a {
  color: #ff5a5f !important;
  font-weight: bold !important;
  font-size: 16px;
}

.grp-btn {
  background-color: transparent;
  color: black;
  border: none;
}

.div-tabel {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px !important;
  margin: 5px !important;
  padding: 10px !important;
  background-color: #f0f0f0;
  cursor: pointer;
}

.div-tabel img {
  height: 40px;
  width: 40px;
  border-radius: 100%;
}

#sub-p {
  color: #959292;
  font-weight: bold;
}

.baby-scroll:hover {
  overflow: auto;
}

.baby-scroll {
  scrollbar-width: none;
}

.baby-scroll {
  overflow: hidden;
}

.baby-scroll::-webkit-scrollbar {
  width: 0;
  height: 2px;
}

.babay_gen_right {
  width: 60%;
}

.babay_gen_left {
  width: 40%;
}

.name-table {
  display: table;
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #dddddd;
}

.name-table-row {
  display: table-row;
}

.name-table-heading,
.name-table-data {
  display: table-cell;
  padding: 8px;
  border-right: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
}

.name-table-heading {
  font-weight: bold;
  background-color: #f2f2f2;
}

.name-table-row .name-table-data:last-child {
  border-right: none;
}

.alphabetButtonWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
}

.alphabetButton {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: lightblue;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.darkFav-icon {
  cursor: pointer;
  color: red !important;
}

.outlineFav-icon {
  cursor: pointer;
  color: red !important;
}

.btn-clear {
  background-color: #fff !important;
  color: black !important;
  border: none !important;
  box-shadow: none !important;
  cursor: pointer;
}

.btn-clear:hover {
  background-color: #fff !important;
  color: #ed4d41 !important;
  box-shadow: none !important;
  border: none;
  cursor: pointer;
}

.btn-clear :focus {
  background-color: #fff !important;
  color: #ed4d41 !important;
  box-shadow: none !important;
  border: none;
  cursor: pointer;
}

.babyname-left-arrow {
  width: 46px;
  height: 46px;
  border-radius: 46px;
  text-align: center;
  line-height: 46px;
  font-size: 1.4rem;
  color: #999;
  border: 1px solid #dfdfdf;
  float: left;
  cursor: pointer;
  margin-right: 6px;
}

.babyname-left-arrow:hover {
  width: 46px;
  height: 46px;
  border-radius: 46px;
  text-align: center;
  line-height: 46px;
  font-size: 1.4rem;
  color: #fff;
  border: 1px solid #ed4d41;
  float: left;
  cursor: pointer;
  margin-right: 6px;
  background-color: #ed4d41;
}

.babyname-right-arrow {
  width: 46px;
  height: 46px;
  border-radius: 46px;
  text-align: center;
  line-height: 46px;
  font-size: 1.4rem;
  color: #999;
  border: 1px solid #dfdfdf;
  float: left;
  cursor: pointer;
  margin-right: 6px;
}

.babyname-right-arrow:hover {
  width: 46px;
  height: 46px;
  border-radius: 46px;
  text-align: center;
  line-height: 46px;
  font-size: 1.4rem;
  color: #fff;
  border: 1px solid #ed4d41;
  float: left;
  cursor: pointer;
  margin-right: 6px;
  background-color: #ed4d41;
}

.preg-calc-img {
  height: 370px;
  width: 100%;
  border-radius: 15px;
  object-fit: cover;
}

.preg-calc-card {
  width: 100%;
  height: 100%;
  background: white;
  box-shadow: 0px 2px 16px rgba(42.5, 42.5, 42.5, 0.15);
  padding: 20px;
  border-radius: 15px;
}

.preg-calc-card button {
  width: 100%;
  background-color: #ed4d41;
  border: 1px solid #ed4d41;
  color: white;
  height: 44px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
}

.preg-calc-card button:hover {
  width: 100%;
  background-color: #ed4d41;
  border: 1px solid #ed4d41;
  color: white;
  height: 44px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
}

.frame-child {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0 0 60px 60px;
  background-color: #ed4d41;
  width: 100%;
  height: 256px;
}

.line-icons {
  position: relative;
  width: 30px;
  height: 20px;
}

.pregnancy-weight-gain {
  position: relative;
  letter-spacing: 0.04em;
  line-height: 130%;
}

.line-icons-parent,
.result {
  position: absolute;
  font-size: 14px;
}

.line-icons-parent {
  top: 40px;
  left: 57px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  color: #fff;
}

.result {
  top: 0;
  left: 0;
  letter-spacing: 0.04em;
  line-height: 22px;
  color: #333;
}

.not-healthy {
  position: relative;
  font-size: 20px;
  letter-spacing: 0.04em;
  color: #e82112;
}

.your-pregnancy-weight-gain-is-parent {
  position: absolute;
  top: 42px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}

.kgm {
  position: relative;
  font-size: 14px;
  letter-spacing: 0.04em;
  line-height: 22px;
}

.your-bmi-body-mass-index-parent,
.your-target-weight-gain-parent {
  position: absolute;
  top: 104px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2px;
}

.your-target-weight-gain-parent {
  top: 160px;
}

.result-parent {
  position: relative;
  width: 100%;
  height: 200px;
}

.result-parent-hcg {
  position: relative;
  width: 100%;
  height: 250px;
}

.result-parent-faq {
  position: relative;
  width: 100%;
  height: auto;
  padding: 30px;
}

.group-wrapper {
  position: absolute;
  top: 92px;
  margin-left: 5%;
  margin-right: 5%;
  border-radius: 25px;
  background-color: #fff;
  box-shadow: 0px 2px 16px rgba(43, 43, 43, 0.15);
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px 16px;
  box-sizing: border-box;
}

.bloodType-image {
  text-align: center;
  top: 92px;
  margin-left: 30%;
  margin-right: 30%;
  background-color: #fff;
  box-shadow: 0px 2px 16px rgba(43, 43, 43, 0.15);
  width: 40%;
  height: 40%;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 100%;
}

.hcg-circle-list {
  list-style-type: circle;
  padding-left: 20px;
}

.group-wrapper-faq {
  border-radius: 25px;
  background-color: #fff;
  box-shadow: 0px 2px 16px rgba(43, 43, 43, 0.15);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px 16px;
  box-sizing: border-box;
}

.frame-item {
  position: absolute;
  top: 405px;
  left: 57px;
}

.rectangle-parent {
  position: relative;
  border-radius: 15px 15px 0px 0px;
  background-color: #fff;
  box-shadow: 2px 2px 16px rgba(43, 43, 43, 0.15);
  width: 100%;
  height: 400px;
  overflow: hidden;
  text-align: left;
  font-size: 12px;
  color: #666;
}

.rectangle-parent-contraction {
  position: relative;
  border-radius: 15px 15px 0px 0px;
  background-color: #fff;
  box-shadow: 2px 2px 16px rgba(43, 43, 43, 0.15);
  width: 100%;
  height: 600px;
  overflow: hidden;
  text-align: left;
  font-size: 12px;
  color: #666;
}

.rectangle-parent-child {
  position: relative;
  border-radius: 15px 15px 0px 0px;
  background-color: #fff;
  box-shadow: 2px 2px 16px rgba(43, 43, 43, 0.15);
  width: 100%;
  height: 500px;
  overflow: hidden;
  text-align: left;
  font-size: 12px;
  color: #666;
}

.recomnded-weight {
  position: relative;
  border-radius: 0px 0px 15px 15px;
  background-color: #fff;
  width: 100%;
  text-align: left;
  font-size: 12px;
  padding: 5%;
  color: #666;
  overflow-x: scroll;
}

.group-wrapper-faq-ques {
  position: relative;
  border-radius: 0px 0px 15px 15px;
  background-color: #fff;
  width: 100%;
  text-align: left;
  font-size: 12px;
  padding: 5%;
  color: #666;
}

.preg-calc-card-res {
  width: 100%;
  background-color: #fff;
  height: auto;
  box-shadow: 2px 2px 16px rgba(43, 43, 43, 0.15);
  border-radius: 15px;
}

.recomnded-weight::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.recomnded-weight {
  scrollbar-width: none;
}

.frame-container {
  width: 100%;
  height: 50px;
  color: #333;
  display: flex;
  justify-content: space-between;
}

.frame-child1 {
  border-radius: 4px;
  width: 20px;
  height: 20px;
}

.rectangle-container {
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 7px;
}

.rectangle-parent1 {
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 7px;
}

.frame-child1,
.group-child10,
.group-child9 {
  background-color: #91e5bd;
  transform-origin: 0 0;
}

.healthy {
  position: relative;
  letter-spacing: 0.04em;
  line-height: 18px;
}

.frame-child2 {
  position: relative;
  border-radius: 4px;
  background-color: #ed4d41;
  width: 20px;
  height: 20px;
  transform-origin: 0 0;
}

.frame-child3 {
  position: relative;
  border-radius: 4px;
  background-color: #f0f0f0;
  width: 20px;
  height: 20px;
  transform-origin: 0 0;
}

.rectangle-parent2 {
  top: 30px;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 7px;
}

.biinfo-circle-fill-parent {
  top: 544px;
  width: 100%;
  left: 0;
  border-radius: 10px;
  background-color: #f0f0f0;
  border: 1px solid #dfdfdf;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 20px;
  gap: 10px;
  text-align: left;
  color: #333;
}

.biinfo-circle-fill-icon {
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}

.this-graph-shows {
  position: relative;
  letter-spacing: 0.04em;
  line-height: 130%;
  display: inline-block;
  width: 100%;
  flex-shrink: 0;
  overflow: hidden;
}

.weeks {
  left: 0;
  line-height: 18px;
  color: #999;
  transform: rotate(-90deg);
  margin-right: 10px;
  text-orientation: mixed;
}

.weight-kg {
  margin-top: 20px;
  text-align: center;
  line-height: 18px;
  color: #999999;
}

.slidecontainer {
  width: 100%;
}

.slider {
  width: 90%;
}

.slider .ant-slider-rail {
  height: 25px;
  background: #d3d3d3;
  border-radius: 35px;
}

.slider .ant-slider-track {
  height: 25px;
  border-radius: 35px;
}

.slider .ant-slider-track :hover {
  height: 25px;
  border-radius: 35px;
}

.slider .ant-slider-track :focus {
  height: 25px;
  border-radius: 35px;
}

.slider .ant-slider-handle {
  width: 25px;
  height: 25px;
  margin-top: 0px;
  background-color: #fff;
}

.slider .ant-slider-handle:hover {
  width: 25px;
  height: 25px;
  margin-top: 0px;
  background-color: #fff;
}

.slider .ant-slider-handle:focus {
  width: 25px;
  height: 25px;
  margin-top: 0px;
  background-color: #fff;
}

.horizontal-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.horizontal-value {
  margin: 0 5px;
}

.highlighted-row {
  background-color: #ed4d41;
  color: #fff;
}

.highlighted-row::hover {
  background-color: #ed4d41 !important;
  color: #fff !important;
}

.recomnded-range-tabel .ant-table-tbody>tr:hover {
  background-color: #fff;
  color: #000;
}

.red-text {
  color: #ed4d41;
}

.message-chat-body * {
  margin: 0;
  padding: 0;
}

.message-chat-ui {
  justify-content: center;
  align-items: center;
  min-height: 20vh;
  background: #ccc;
  width: 100%;
  margin-bottom: 0px;
  border: none !important;
}

.background-green {
  position: absolute;
  top: 0;
  width: 100%;
  height: 20%;
  background-color: #009688;
}

.message-chat-main-container {
  position: relative;
  width: 100%;
  max-width: 100%;

  height: calc(100vh - 20vh);
  background: #fff;
  display: flex;
  box-shadow: 0px 1px 1px 0 rgba(0, 0, 0, 0.5), 0px 2px 5px 0 rgba(0, 0, 0, 0.6);
  scroll-behavior: smooth;
}

.message-chat-main-container .message-chat-left-container {
  position: relative;
  width: 30%;
  height: 100%;
  flex: 30%;
  background: #fff;
}

.message-chat-main-container .message-chat-right-container {
  position: relative;
  width: 70%;
  height: 100%;
  flex: 70%;
  background: #e5ddd5;
}

.message-chat-main-container .message-chat-right-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
}

.message-chat-header {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  background: #ededed;
  padding: 0 15px;
}

.message-chat-user-img {
  position: relative;
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
}

.dp {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.notif-box {
  position: relative;
  display: flex;
  width: 100%;
  height: 70px;
  background: #76daff;
  align-items: center;
  font-size: 0.8em;
  text-decoration: none;
}

.notif-box i {
  position: relative;
  left: 13px;
  background: #fff;
  padding: 10px;
  width: 42px;
  height: auto;
  font-size: 20px;
  border-radius: 55%;
  cursor: pointer;
  color: #76daff;
}

.notif-box .fa-xmark {
  position: absolute;
  left: 260px;
  text-align: center;
  background: #76daff;
  color: #fff;
}

.notif-text {
  margin: 25px;
}

.notif-text a {
  text-decoration: none;
  color: #333;
  font-size: 0.9em;
}

.chat-list {
  position: relative;
  height: calc(100% - 60px);
  overflow-y: auto;
}

.chat-list .chat-box {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 15px;
  border-bottom: 1px solid #eee;
}

.chat-list .chat-box .img-box {
  position: relative;
  width: 55px;
  height: 45px;
  overflow: hidden;
  border-radius: 50%;
}

.chat-list .chat-box .chat-details {
  width: 100%;
  margin-left: 10px;
}

.chat-list .chat-box .chat-details .text-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
}

.chat-list .chat-box .chat-details .text-head h4 {
  font-size: 1.1em;
  font-weight: 600;
  color: #000;
}

.chat-list .chat-box .chat-details .text-head .time {
  font-size: 0.8em;
  color: #aaa;
}

.chat-list .chat-box .chat-details .text-message {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chat-list .chat-box .chat-details .text-message p {
  color: #aaa;
  font-size: 0.9em;
  overlay: hidden;
}

img {
  width: 100%;
  object-fit: cover;
}

.chat-list .chat-box .chat-details .text-message b {
  background: #ed4d41;
  color: #fff;
  min-width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 0.8em;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-list .active {
  background: #ffe9fb;
}

.chat-list .chat-box:hover {
  background: #f5f5f5;
}

.chat-list .chat-box .chat-details .text-head .unread {
  color: #999;
}

.message-chat-right-container.message-chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.message-chat-right-container .message-chat-header .img-text .message-chat-user-img .dp {
  position: relative;
  top: -2px;
  left: 0px;
  width: 40px;
  height: auto;
  overflow: hidden;
  object-fit: cover;
}

.message-chat-right-container .message-chat-header .img-text {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.message-chat-right-container .message-chat-header .img-text h4 {
  font-weight: 500;
  line-height: 1.2em;
  margin-left: 15px;
}

.message-chat-right-container .message-chat-header .img-text h4 span {
  font-size: 0.8em;
  color: #555;
}

.message-chat-container {
  position: relative;
  width: 100%;
  height: calc(100% - 120px);
  padding: 50px;
  overflow-y: auto;
}

.chat-ui-message-box {
  position: relative;
  display: flex;
  width: 100%;
  margin: 5px 0;
}

.chat-ui-message-box p {
  position: relative;
  right: 0;
  max-width: 65%;
  padding: 12px;
  background: #fad3d0;
  border-radius: 10px;
  font-size: 0.9em;
}

.chat-ui-message-box.my-message p::before {
  content: "";
  position: absolute;
  top: 0;
  right: -12px;
  width: 20px;
  height: 20px;
  background: linear-gradient(135deg, #fad3d0 0%, #fad3d0 50%, transparent 50%, transparent);
}

.chat-ui-message-box p span {
  display: block;
  margin-top: 5px;
  font-size: 0.8em;
  opacity: 0.5;
}

.my-message {
  justify-content: flex-end;
}

.friend-message p {
  background: #f0f0f0;
}

.friend-message {
  justify-content: flex-start;
}

.message-chat-container .my-message i {
  color: yellow;
}

.chat-ui-message-box.friend-message::before {
  content: "";
  position: absolute;
  top: 0;
  left: -12px;
  width: 20px;
  height: 20px;
  background: linear-gradient(225deg, #f0f0f0 0%, #f0f0f0 50%, transparent 50%, transparent);
}

.message-chatbox-input {
  position: relative;
  width: 100%;
  height: 60px;
  background: #f0f0f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.message-chatbox-input i {
  cursor: pointer;
  font-size: 1.8em;
  color: #515855;
}

.message-chatbox-input i:nth-child(1) {
  margin: 15px;
}

.message-chatbox-input i:last-child {
  margin-right: 25px;
}

.message-chatbox-input input {
  position: relative;
  width: 90%;
  margin: 0 20px;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 1em;
  border: none;
  outline: none;
}

.message-chat-input {
  border: 1px solid #ccc !important;
  border-radius: 4px !important;
  padding: 8px !important;
  margin-right: 8px !important;
  width: 100% !important;
}

.message-chat-input-btn {
  background: transparent;
  border: 1px solid #ccc;
  color: black;
  border-radius: 4px;
  height: 39px;
  padding: 0 15px;
  display: flex;
  align-items: center;
}

.mess_delete_chat {
  padding: 4px;
  margin-right: 10px;
  text-align: center;
  font-weight: bold;
}

.icon-arrow-down,
.ant-btn-icon-only {
  border: none;
  background-color: red;
}

.icon-arrow-down:hover {
  border: none;
  background-color: red;
}

.message-chat-nav-icons {
  display: flex;
  float: right;
  padding-left: 80%;
  overflow: hidden;
}

.message-chat-nav-icons li {
  list-style: none;
  cursor: pointer;
  color: #51585c;
  margin-left: 22px;
  font-size: 18px;
  justify-content: end;
}

.open-button {
  padding: 10px 20px;
  border: none;
  border-radius: 12px;
  background-color: #ed4d41;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.open-button:hover {
  background-color: #ed4d41;
}

.btn-new-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
  background-color: #80808026;
  padding: 0px !important;
  position: fixed;
  z-index: 10000000;
  width: 100% !important;
  padding: 15px 15px !important;
  margin: 0px;
  cursor: pointer;
}

.open-btn-app {
  background-color: #ed4d41;
  color: white;
  border-radius: 11px;
  width: 60px;
  margin: 0px;
  text-align: center;
  line-height: normal;
  padding: 2px;
}

.frame-child-blood {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0 0 50% 50%;
  background-color: #ed4d41;
  width: 100%;
  height: 363px;
}

.frame-child-blood-contraction {
  top: 0;
  left: 0;
  border-radius: 0 0 50% 50%;
  background-color: #ed4d41;
  width: 100%;
  height: 486px;
}

.frame-parent {
  left: 65px;
  justify-content: center;
  gap: 26px;
  color: #ec5e53;
}

.frame-group {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 25px;
}

.a-wrapper,
.frame-parent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.a-wrapper,
.o-wrapper {
  border-radius: 50%;
  border: 2px solid #ec5e53;
  padding: 19px 20px;
}

.o-wrapper {
  width: 60px;
}

.o {
  position: relative;
}

.blood-type-preg {
  width: 70% !important;
  margin-top: 24px auto;
}

@media screen and (max-width: 768px) {

  .a-wrapper,
  .o-wrapper {
    padding: 8px 10px;
  }

  .predicted-child-eye-card {
    width: 100%;
  }

  .o-wrapper {
    width: 40px;
  }

  .blood-type-preg {
    width: 100% !important;
  }

  .child-blood-img {
    width: 140px !important;
    height: 140px !important;
    text-align: center !important;
    margin-top: 186px !important;
    margin-left: -21px;
  }

  .rectangle-parent-child {
    position: relative;
    border-radius: 15px 15px 0px 0px;
    background-color: #fff;
    box-shadow: 2px 2px 16px rgba(43, 43, 43, 0.15);
    width: 100%;
    height: 400px;
    overflow: hidden;
    text-align: left;
    font-size: 12px;
    color: #666;
  }

  .frame-child-blood {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0 0 50% 50%;
    background-color: #ed4d41;
    width: 100%;
    height: 310px;
  }

  .ellipse-parent-blood-type {
    position: relative;
    width: 40%;
    height: 201.7px;
    text-align: center;
    font-size: 10px;
    color: #333;
    justify-content: center;
    margin-left: -5% !important;
  }

  .possible-blood-rh-type-parent {
    margin-left: -50% !important;
  }
}

.possible-blood-rh {
  width: 200px;
  height: 200px;
  margin-left: 37%;
}

.o {
  position: relative;
}

.child-blood-img {
  border-radius: 100%;
  margin-top: 170px;
}

.child-blood-type-res {
  width: 100%;
  background-color: #fff;
  height: auto;
  box-shadow: 2px 2px 16px rgba(43, 43, 43, 0.15);
  border-radius: 15px;
  padding: 24px;
  text-align: center;
}

.group-child-blood-type {
  top: 0;
  left: 42.8px;
  background-color: #f7574b;
  width: 175.2px;
  height: 173.7px;
}

.group-child-blood-type,
.group-inner-blood-type,
.group-item-blood-type {
  position: absolute;
  border-radius: 50%;
  border: 7.5px solid #fff;
  box-sizing: border-box;
}

.group-item-blood-type {
  top: 75.7px;
  left: 0;
  background-color: #f17a71;
  width: 126.9px;
  height: 125.8px;
}

.group-inner-blood-type {
  top: 129.7px;
  left: 116px;
  background-color: #f6a6a0;
  width: 72.4px;
  height: 71.8px;
}

.p {
  margin: 0;
}

.ab {
  position: relative;
  letter-spacing: 0.04em;
  line-height: 16.5px;
}

.a-wrapper,
.ab-wrapper,
.b-wrapper {
  position: absolute;
  top: 155.4px;
  left: 147.4px;
  border-radius: 95.25px;
  background-color: #fff;
  box-shadow: 0px 2px 16px rgba(43, 43, 43, 0.15);
  width: 46.7px;
  height: 46.3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 14.25px 15px;
  box-sizing: border-box;
}

.a-wrapper,
.b-wrapper {
  top: 151.3px;
  left: 0;
}

.b-wrapper {
  top: 26.8px;
  left: 171.3px;
}

.ellipse-parent-blood-type {
  position: relative;
  width: 40%;
  height: 201.7px;
  text-align: center;
  font-size: 10px;
  color: #333;
  justify-content: center;
  margin-left: 35%;
}

.possible-blood-rh-type {
  position: absolute;
  top: 82px;
  left: 150px;
  font-size: 14px;
  letter-spacing: 0.04em;
  line-height: 22px;
  font-weight: 500;
  color: #666;
}

.frame-child-bllod-res {
  position: absolute;
  top: 128px;
  left: 136px;
  width: 175px;
  height: 175px;
  object-fit: cover;
  border-radius: 100%;
}

.frame-item-bllod-res {
  position: relative;
  border-radius: 50%;
  background-color: #ff3b30;
  width: 8px;
  height: 8px;
}

.negative {
  position: relative;
  letter-spacing: 0.04em;
  line-height: 16.5px;
}

.ellipse-parent {
  position: absolute;
  top: 136px;
  left: 273px;
  border-radius: 95.25px;
  background-color: #fff;
  box-shadow: 0px 2px 16px rgba(43, 43, 43, 0.15);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  gap: 7.5px;
}

.frame-inner {
  position: relative;
  border-radius: 50%;
  background-color: #28cd41;
  width: 8px;
  height: 8px;
}

.ellipse-group {
  position: absolute;
  top: 278px;
  left: 102px;
  border-radius: 95.25px;
  background-color: #fff;
  box-shadow: 0px 2px 16px rgba(43, 43, 43, 0.15);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  gap: 7.5px;
}

.possible-blood-rh-type-parent {
  position: relative;
  width: 428px;
  height: 428px;
  overflow: hidden;
  text-align: center;
  font-size: 10px;
  color: #333;
  text-align: center;
  margin-left: 25%;
  margin-right: 25%;
}

.mobile-img-heder-logo {
  height: 13px;
  width: 100%;
  display: block;
}

.primary-btn-btp {
  width: 100%;
  background-color: #ed4d41 !important;
  border-radius: 8px !important;
  padding: 10px !important;
  gap: 10px !important;
  border: none !important;
  color: #fff;
  font-weight: bold;
}

input[type="radio"] {
  appearance: none;
}

input[type="radio"]+label {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

input[type="radio"]:checked+label {
  background: #ed4d41;
  color: white;
  border-radius: 50%;
  border: 2px solid #ec5e53;
  width: 57px;
  height: 62px;
  left: -21px;
  top: -20px;
  overflow: hidden;
  cursor: pointer;
}

.brown-wrapper,
.frame-parent-child-eye,
.green-wrapper {
  display: flex;
  flex-direction: column;
}

.brown-wrapper {
  border-radius: 127px;
  background: linear-gradient(0deg, rgba(198, 123, 10, 0), rgba(222, 166, 95, 0));
  box-shadow: 0px 2px 16px rgba(43, 43, 43, 0.15);
  border: 1px solid #000;
  box-sizing: border-box;
  width: 60px;
  height: 60px;
  align-items: center;
  justify-content: center;
  padding: 19px 20px;
}

.predicted-child-eye-card {
  box-shadow: 0px 2px 16px rgba(43, 43, 43, 0.15);
  background-color: #ffffff;
  text-align: center;
  border: none !important;
  width: 90%;
  border-radius: 13px !important;
}

.apexcharts-legend.apx-legend-position-right,
.apexcharts-legend.apx-legend-position-left {
  flex-direction: column-reverse !important;
  bottom: 0;
  text-align: justify !important;
}

.apexcharts-legend-text {
  position: relative;
  font-size: 13px !important;
}

.green-sent-btn {
  color: #079854 !important;
}

.week-banner-card {
  border-radius: 8px;
  height: auto;
  text-align: center;
}

.week-text-p {
  font-weight: bold;
  letter-spacing: 0.88px;
  margin-top: 10px;
  font-size: 45px;
}

.week-c-text {
  font-size: 20px;
  font-weight: bold;
  color: #000;
  letter-spacing: 0.56px;
}

.s-between {
  justify-content: space-between !important;
}

.week-ban-inner-card {
  text-align: center;
  padding: 20px;
  width: 82%;
  margin-left: 10%;
  justify-content: center;
}



.week-c-num {
  font-size: 24px;
  font-weight: bold;
  color: #000;
  letter-spacing: 0.56px;
}

.week-c-span {
  font-weight: bold;
  font-size: 29px;
}

.week-c-btn {
  width: 100%;
  color: #fff;
  padding: 10px;
  justify-content: center;
  border-radius: 8px;
  font-size: 16px;
  letter-spacing: 0.48px;
  font-weight: 5000;
}

.week-mini-card {
  border-radius: 8px;
  padding-top: 25px;
  padding-left: 25px;
  padding-bottom: 0px;
  width: 250px;
  object-fit: cover;
  margin-top: 10px;
  margin-bottom: 20px;
}

.week-mini-img {
  margin-bottom: 0px;
  text-align: left;
  width: 100%;
}

.week-mini-title {
  font-weight: bold;
  font-size: 23px;
  letter-spacing: 0.64px;
}

.week-mini-subtitle {
  font-size: 19px;
  letter-spacing: 0.64px;
}

.week-key-card {
  border-radius: 16px;
  gap: 12;
  padding: 20px;
  margin-top: 25px;
}

.week-key-card h3 {
  letter-spacing: "0.56px";
  font-size: "20px";
}

.week-key-card p {
  font-size: 14px;
  letter-spacing: 0.56px;
  font-weight: bold;
  line-height: 20px;
}

.sympt-card img {
  height: 200px;
  object-fit: contain;
  text-align: center;
  margin-top: 20px;
}

.sympt-p-1 {
  font-size: 20px;
  color: black;
  font-weight: 500;
  letter-spacing: 4%;
  text-align: center;
}

.sympt-p-2 {
  color: #1e1c1c;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.52px;
}

.sympt-pd {
  color: #666666;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.52px;
}

.sympt-card {
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  flex: 0 0 auto;
  height: 65vh;
  max-height: 600px;
  overflow: hidden;
}

.post-cards {
  padding: 20px;
  border-radius: 8px;
  width: 320px;
  flex: 0 0 auto;
  height: 50vh;
  max-height: 500px;
  overflow: hidden;
  margin-bottom: 30px;
}

@media screen and (max-width: 768px) {
  .post-cards {
    padding: 20px;
    border-radius: 8px;
    width: 320px;
    flex: 0 0 auto;
    height: 60vh;
    max-height: 500px;
    overflow: hidden;
    margin-bottom: 30px;
    margin-top: 7px;
  }

  .sympt-card {
    padding: 20px;
    border-radius: 8px;
    width: 450px;
    flex: "0 0 auto" !important;
    height: auto;
  }
}

.home-post-btn {
  background-color: rgb(255, 90, 95) !important;
  color: #ffff !important;
  border-radius: 5px !important;
  padding: 5px 40px !important;
}


.prof-pic-home {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
}

.post-icon {
  opacity: 0.2;
  cursor: pointer;
}

.post-media-form {
  width: 100%;
  max-width: 60px;
}







.grp-post-img {
  width: 100%;
  margin: 0;
  padding: 0;
  height: 450px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.grp-post-img img {
  max-width: 100%;
  max-height: 100%;
  // object-fit: contain;
}

.grp-post-img-my{
  width: 100%;
  margin: 0;
  padding: 0;
  height: 450px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.grp-post-img-my img{
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  object-fit:fill;

}


.week-card-border-line {
  border: 1px solid gray;
  border-radius: 8px;
  width: 100%;
}

.sympt-card-close {
  float: right;
  padding: 10px 10px 0px 10px
}

.close-outline-btn {
  font-weight: bold;
  font-size: 15px;
}

.tips-of-week {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  text-align: center;
}


.left-right-tips-tbn {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  font-weight: bold;
}


.tips-of-week-img {
  width: 70%;
  margin-left: 15%;
  height: 300px;
}

.tips-para {
  font-weight: 600;
  margin-top: 30px;
}

.tips-of-week-p {
  font-weight: 600;
}

.progress-percentage {
  margin-top: 10;
  text-align: center;
  font-weight: bold;
}

.track-weekly {
  background-color: #BE52F2;
  padding: 28px;
  gap: 16px;
  color: #ffff;
}

.track-weekly p {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.6px;
}


.todo-list-card {
  align-items: start;
  margin-top: 10px;
  text-align: left;
}

.track-weekly-check-list {
  padding: 28px
}

.more-itme-p {
  letter-spacing: 0.56px;
  padding: 28px;
  font-size: 16px;
  color: #666;
  gap: 16px;
  font-weight: 500;
}

.is-tickable-title {
  padding: 28px;
}

.weekcard-scroll {
  overflow-x: auto;
  width: 100%;
  padding: 8px;
}

.w-100 {
  width: 100%;
}


.changing-body {
  gap: 12px;
  flex: 0 o auto;
}

.changing-body-h-tag {
  font-size: 21px;
  font-weight: bold;
  color: black;
}

.changing-body-substory-card {
  padding: 20px;
  gap: 12px;
  border-radius: 8px;
  width: 350px;
  flex: 0 0 auto;
}


.changing-body-substory-card img {
  height: 13px;
  object-fit: contain;
  text-align: center;
  margin-top: 20px;
}

.changing-body-artc {
  padding: 8px;
  border-radius: 8px;

}

.spc-btn {
  justify-content: space-between;
}

.changing-body-artc-p {
  color: #090808;
  font-size: bold;
  margin-top: 2px;
}

.artc-icon-btn {
  font-weight: 600;
  font-size: .9375rem;
}

.f-20 {
  font-size: 20px;
}

.changing-body-frag {
  padding: 8px;
}

.mb-20 {
  margin-bottom: 20px;
}

.p-20 {
  padding: 20px;
}

.your-b-img {
  margin-top: 35px;
  background-color: inherit;
  border-radius: inherit;
  display: block;
  margin: 0 auto;
  text-align: center !important;
  justify-content: center !important;
  margin-left: 10%;
}

.mini-card-container {
  width: 100%;
  height: auto;
  overflow-x: auto;
}

.post-cards img {
  height: 130px;
  object-fit: contain;
  text-align: center;
  margin-top: 60px;
  margin-bottom: 20px;
}

.p-card-description {
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.52px;
}
.p-10{
  padding: 10px;
}


.postPrivacy .ant-select.ant-select-in-form-item {
  width: 50%;
  float: right;
  margin-right: 11px;
  height: 32px !important;
  text-align: left;
}


.postPrivacy .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  height: 32px !important;
  width: 100%;
  position: relative;
  display: inline-block;

}

.white-space-preline{
  white-space: pre-line;
}

.post-form-text-danger{
  color: red;
}

.post-control-form{
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

 .user-post .ant-upload.ant-upload-select-picture-card {
    width:  auto;
    height: 20px;
    margin-right: 8px;
    margin-bottom: 8px;
    text-align: center;
    vertical-align: top;
    background-color:transparent !important;
    border: none;
    border-radius: 2px !important;
    cursor: pointer;
    transition: border-color 0.3s;
}

.pinned-container {
  display: flex;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 2px;
  background-color: #FAD3D0;
}

.pinned-icon {
  margin-right: 8px;
}

.pinned-text {
  margin-right: 20px !important;
  font-weight: bold !important;
}

.loc-img{
  width: 100px;
  height: 100px;
  border-radius: 12px;
}

