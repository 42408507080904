.custom-tabs {

  .ant-tabs-tab {
    padding: 0 !important;
  }

  .ant-tabs-nav {
    padding: 0 0 0 12px;
  }

  .ant-tabs-nav {
    margin:  0 0 12px 0;
  }

  .ant-tabs-tab .ant-tabs-tab-btn {
    color: #ffffff;
    border-radius: 50px;
    background-color: transparent;
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
    padding: 4px 10px; 
  }
  
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #ff5a5f !important;
    background-color: #fff;
 }

  .ant-tabs-ink-bar {
    display: none;
  }
}

.custom-tabs-default {

  .ant-tabs-tab {
    padding: 0 !important;
  }

  .ant-tabs-nav {
    padding: 0 0 0 12px;
  }

  .ant-tabs-nav {
    margin:  0 0 12px 0;
  }

  .ant-tabs-tab .ant-tabs-tab-btn {
    color: #b91c1c;
    border-radius: 50px;
    background-color: transparent;
    font-weight: 600;
    font-size: 16px;
    // opacity: 0.5;
    text-transform: capitalize;
    padding: 0.25rem 3rem 0.25rem 0.25rem; 
  }
  
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    opacity: 1;
 }

  .ant-tabs-ink-bar {
    display: block;
  }
}

.custom-horiz-tabs {

  > .ant-tabs-nav {
    margin: 0 0 0 1rem;

    > .ant-tabs-nav-wrap {
      background-color: #000000;
      width: 80%;
    }
  }

  .custom-horiz-tab > .ant-tabs-tab-btn {
    color: #fff;
    border-radius: 50px;
    background-color: transparent;
    font-weight: 600;
    font-size: 16px;
    opacity: 0.5;
    text-transform: capitalize;
  }

  .custom-horiz-tab.ant-tabs-tab-active > .ant-tabs-tab-btn {
    opacity: 1;
  }

  .custom-horiz-tab + .ant-tabs-ink-bar {
    display: block;
    background-color: #fff;
  }
}

.custom-tabs.ant-tabs-top > .ant-tabs-nav::before, 
.custom-tabs.ant-tabs-bottom > .ant-tabs-nav::before, 
.custom-tabs.ant-tabs-top > div > .ant-tabs-nav::before, 
.custom-tabs.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border: 0;
}