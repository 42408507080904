@font-face {
    font-family: 'geomanistregular_normal';
    src: url('../fonts/geomanist-regular-webfont.woff2') format('woff2'),
         url('../fonts/geomanist-regular-webfont.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-size: 14px;
  }
  @font-face {
    font-family: 'geomanistregular_black';
    src: url('../fonts/geomanist-black-webfont.woff2') format('woff2'),
         url('../fonts/geomanist-black-webfont.woff') format('woff');
    font-weight: 100;
    font-style: normal;
  
  }
  /* bold */
  
  @font-face {
    font-family: 'geomanistregular_bold';
    src: url('../fonts/geomanist-bold-webfont.woff2') format('woff2'),
         url('../fonts/geomanist-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'geomanistregular_book';
   src:  url('../fonts/geomanist-book-webfont.woff2') format('woff2'),
         url('../fonts/geomanist-book-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'geomanistregular_extralight';
    src: url('../fonts/geomanist-extralight-webfont.woff2') format('woff2'),
         url('../fonts/geomanist-extralight-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  
  }
  
  @font-face {
    font-family: 'geomanistregular_light';
    src: url('../fonts/geomanist-light-webfont.woff2') format('woff2'),
         url('../fonts/geomanist-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  
  }
  @font-face {
    font-family: 'geomanistregular_medium';
    src: url('../fonts/geomanist-medium-webfont.woff2') format('woff2'),
         url('../fonts/geomanist-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  
  }
  
  @font-face {
    font-family: 'geomanistregular_thin';
    src: url('../fonts/geomanist-thin-webfont.woff2') format('woff2'),
         url('../fonts/geomanist-thin-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  
  }
  
  @font-face {
    font-family: 'geomanistregular_ultra';
    src: url('../fonts/geomanist-ultra-webfont.woff2') format('woff2'),
         url('../fonts/geomanist-ultra-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  } 
  body{
    font-family: "geomanistregular_normal" !important;
  }
h1{font-family: "geomanistregular_bold";}
h2{font-family: "geomanistregular_medium";}
h3{font-family: "geomanistregular_book";}
h4{font-family: "geomanistregular_extralight";}
span{font-family:"geomanistregular_normal" !important}
button{font-family: "geomanistregular_bold";}


p{font-family: "geomanistregular_normal";}

  p.name2{
    font-family: "geomanistregular_black";
  }
  p.name3{
   
  }
  p.name4{
    font-family: "geomanistregular_book";
  }
  p.name5{
    font-family: "geomanistregular_extralight";
  }
  p.name6{
    font-family: "geomanistregular_light";
  }
  p.name7{
    font-family: "geomanistregular_medium";
  }
  p.name8{
    font-family: "geomanistregular_thin";
  }
  p.name9{
    font-family: "geomanistregular_ultra";}
    // 
    .name2{
      font-family: "geomanistregular_black";
    }
    .name3{
     
    }
    .name4{
      font-family: "geomanistregular_book";
    }
    .name5{
      font-family: "geomanistregular_extralight";
    }
    .name6{
      font-family: "geomanistregular_light";
    }
    .name7{
      font-family: "geomanistregular_medium";
    }
    .name8{
      font-family: "geomanistregular_thin";
    }
    .name9{
      font-family: "geomanistregular_ultra";}