@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
   .pink_text{
     @apply text-red-400  text-right 
   }
}
@layer components {
  .pink_button{
    @apply bg-[#ff5a5f] text-[#fff] rounded-md
  }
}
